import { unref } from 'vue'

import type { MaybeElement, MaybeElementRef, UnRefElementReturn, VueInstance } from '@vueuse/core'

/**
 * Get the dom element of a ref of element or Vue component instance
 *
 * @param elRef
 */
export function unrefElement<T extends MaybeElement>(
  elRef: MaybeElementRef<T>,
): UnRefElementReturn<T> {
  const plain = unref(elRef)

  return (
    plain != null && '$el' in (plain as VueInstance) ? (plain as VueInstance).$el : plain
  ) as UnRefElementReturn<T>
}

import { type MaybeElementRef, type UseCssVarOptions, defaultWindow } from '@vueuse/core'
import { toValue } from '@vueuse/shared'
import { computed, ref, watch } from 'vue-demi'

import type { MaybeRefOrGetter } from '@vueuse/shared'

// useCssVar implementation without overwriting the css variable if it already has a value
export function useCssVar(
  prop: MaybeRefOrGetter<string>,
  target?: MaybeElementRef,
  options: UseCssVarOptions = {},
) {
  const { window = defaultWindow, initialValue = '', observe = false } = options
  const variable = ref(initialValue)
  const current = ref<string>()
  const elRef = computed(() => unrefElement(target) || window?.document?.documentElement)

  function updateCssVar() {
    const propName = toValue(prop)
    const el = toValue(elRef)
    if (el && window) {
      current.value = window.getComputedStyle(el).getPropertyValue(propName)?.trim()
      variable.value = current.value || initialValue
    }
  }

  if (observe) {
    useMutationObserver(elRef, updateCssVar, {
      attributeFilter: ['style', 'class'],
      window,
    })
  }

  watch([elRef, () => toValue(prop)], updateCssVar, { immediate: true })

  watch(variable, (val) => {
    if (val === current.value) return

    if (elRef.value?.style) {
      elRef.value.style.setProperty(toValue(prop), val)
    }
  })

  return variable
}

<script lang="ts">
import { captureException } from '@sentry/vue'

import { PROJECT_TRANSITION_BACKGROUND_CLASS } from '~/components/sections/ProjectSection.vue'

export const BACKGROUND_MEDIA_CLASS = 'background-media'
</script>

<script lang="ts" setup>
defineProps<{
  backgroundColor?: string
  backgroundImage?: string
  backgroundVideo?: string
  backgroundPosition?: 'object-top' | 'object-center' | 'object-bottom'
  canUseTransitionBackground?: boolean
  blackTint?: boolean
  videoObjectFit?: 'object-cover' | 'object-none'
}>()

const transitionRef = ref<HTMLElement>()
const videoRef = ref<HTMLVideoElement>()

const previousTransitionBackground =
  import.meta.client &&
  (document.querySelector(`body > .${PROJECT_TRANSITION_BACKGROUND_CLASS}`) as
    | HTMLElement
    | undefined)

onMounted(() => {
  // manual autoplay
  if (!videoRef.value) return

  videoRef.value.play().catch((err: Error) => {
    // log error if it's not related to autoplay
    if (err.name !== 'NotAllowedError') {
      captureException(err)
      console.error(err)
      return
    }

    // try to play video on click
    useEventListener(
      document,
      'click',
      () => {
        if (!videoRef.value) return

        videoRef.value.play().catch((err) => {
          captureException(err)
          console.error(err)
        })
      },
      { once: true, passive: true },
    )
  })
})

onMounted(() => {
  if (!transitionRef.value || !previousTransitionBackground) return

  transitionRef.value.replaceWith(previousTransitionBackground)
  previousTransitionBackground.style.position = 'absolute'
})

useNuxtApp().hooks.hookOnce('page:finish', () => {
  if (!previousTransitionBackground) return

  console.debug('window.scrollTo({ top: 0 }) on page:finish')
  window.scrollTo({ top: 0 })
})
</script>

<template>
  <div
    :class="[BACKGROUND_MEDIA_CLASS]"
    class="absolute inset-0"
    :style="{
      backgroundColor,
    }"
  >
    <div v-if="previousTransitionBackground && canUseTransitionBackground" ref="transitionRef" />
    <template v-else>
      <Image
        v-if="backgroundImage"
        :src="backgroundImage"
        :draggable="false"
        loading="eager"
        class="absolute inset-0 h-full w-full object-cover"
        :class="[backgroundPosition]"
      />
      <video
        v-if="backgroundVideo"
        ref="videoRef"
        :src="backgroundVideo"
        :poster="backgroundImage ?? undefined"
        muted
        playsinline
        loop
        class="absolute inset-0 h-full w-full"
        :class="[backgroundPosition, videoObjectFit || 'object-cover']"
      />
    </template>
    <div
      v-if="blackTint && !previousTransitionBackground"
      class="absolute inset-0 h-full w-full bg-black/20"
    />
  </div>
</template>
